<div class="flex flex-col md:flex-row md:justify-between">
  <h1 class="text-3xl font-medium" i18n>Users</h1>
</div>

<div class="mt-6">
  <app-admin-users-search (search)="search($event)" />
</div>

<div class="mt-10 w-full">
  <div
    class="grid grid-cols-1 gap-x-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
  >
    <div
      class="relative mb-6 w-full rounded-xl p-6 shadow bg-white"
      *ngFor="let user of users$ | async"
    >
      <div *ngIf="user.organisation" class="absolute right-4 top-4">
        <app-button
          icon="ellipsis"
          iconFill="#1990FF"
          classes="h-10 w-10"
          (click)="userMenuOpen = user.userId"
          (keydown.enter)="userMenuOpen = user.userId"
        />
      </div>

      <div>
        <h2 i18n class="text-sm font-light text-alpha-gray">Name</h2>
        <p class="mb-5 mt-1 text-xl text-black">
          {{ user.profile.firstName }} {{ user.profile.lastName }}
        </p>
      </div>

      <div>
        <h2 i18n class="text-sm font-light text-alpha-gray">Organisation</h2>
        <p class="mb-5 mt-1 text-xl text-black">
          {{ user.organisation?.name || 'No Organisation'}}
        </p>
      </div>

      <div>
        <h2 i18n class="text-sm font-light text-alpha-gray">Phone number</h2>
        <p class="mb-5 mt-1 text-xl text-black">
          {{ user.profile.phoneNumber }}
        </p>
      </div>

      <div>
        <h2 i18n class="text-sm font-light text-alpha-gray">Email address</h2>
        <p class="mb-5 mt-1 text-xl text-black">{{ user.profile.email }}</p>
      </div>

      <div class="mt-10">
        <span
          [ngClass]="{
              'px-6 py-2 rounded-full margin': true,
              'bg-alpha-green': user.status === UserStatusEnum.ACTIVE,
              'bg-yellow-200': user.status === UserStatusEnum.INVITED,
              'bg-red-200': !user.organisation,
          }"
        >
          {{ user.status | titlecase }}
        </span>
      </div>

      <app-menu
        [show]="userMenuOpen === user.userId"
        (closed)="userMenuOpen = ''"
      >
        <app-menu-action
          i18n-text
          text="View user details"
          (click)="viewUser(user)"
          (keydown.enter)="viewUser(user)"
        />
      </app-menu>
    </div>
  </div>

  <div
    *ngIf="(isPending$ | async) === false && !(users$ | async)?.length"
    class="mt-20 flex items-center justify-center"
  >
    <h1 class="text-3xl text-alpha-gray-80">
      No results, please try another search.
    </h1>
  </div>

  <div
    *ngIf="(isPending$ | async)"
    class="mt-20 flex items-center justify-center"
  >
    <app-loading />
  </div>
</div>
