import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Store } from '@ngrx/store';

import { ButtonComponent } from "../../../../../components/button/button.component";
import { IconComponent } from "../../../../../components/icon/icon.component";
import { OrganisationSearchParams } from "../../../../../interfaces/organisation.interface";

@Component({
  selector: 'app-admin-users-search',
  standalone: true,
  templateUrl: './users-search.component.html',
  imports: [CommonModule, IconComponent, ReactiveFormsModule, ButtonComponent, FormsModule],
})
export class UsersSearchComponent {
  @Output()
  public search: EventEmitter<OrganisationSearchParams> =
    new EventEmitter<OrganisationSearchParams>();

  public readonly searchForm = new FormGroup({
    q: new FormControl(''),
  });

  public constructor(private readonly store: Store) {}

  public async startSearch(): Promise<void> {
    const { q } = this.searchForm.value;
    const searchTerm = q?.trim();

    this.search.emit({ q: searchTerm });
  }
}
