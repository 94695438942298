<div class="flex items-center justify-between">
  <div
    [formGroup]="searchForm"
    class="flex flex-1 items-center rounded border border-alpha-gray-40 p-2 px-2.5"
  >
    <div class="h-5"><app-icon icon="search" /></div>

    <input
      formControlName="q"
      (input)="startSearch()"
      (keyup.enter)="startSearch()"
      type="search"
      i18n-placeholder
      placeholder="Start typing a users name..."
      class="ml-2 w-full outline-none"
    />
  </div>
  <div class="ml-4 flex w-32 items-center justify-between">
    <app-button
      class="w-full"
      (click)="startSearch()"
      (keydown.enter)="startSearch()"
      text="Search"
      i18n-text
      type="submit"
      classes="px-3 w-full"
    />
  </div>
</div>
